#root {
  max-width: 1280px;
  text-align: center;
  margin: 0 auto;
  padding: 2rem;
}

.App {
  text-align: center;
  font-family: proxima-nova, sans-serif;
}

.gap {
  height: 25px;
  width: 100%;
}

.minipadding {
  height: 1px;
  width: 100%;
}

.Desc {
  color: #fff;
  text-align: left;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20%;
  padding-right: 20%;
  font-family: proxima-nova, sans-serif;
  font-size: 1.05em;
  font-style: normal;
  font-weight: 100;
}

.SubTitle {
  color: #510c0c;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  font-family: proxima-nova, sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
}

.HeaderLogo {
  width: 55%;
  height: auto;
}

.RoadMapLogo, .BioLogo, .AboutLogo {
  width: 35%;
}

.video {
  height: 60%;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 25px;
  padding-bottom: 25px;
}

@media screen and (max-width: 770px) {
  .Desc {
    text-align: left;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: 5%;
    padding-right: 5%;
    font-family: proxima-nova, sans-serif;
    font-style: normal;
    font-weight: 100;
  }

  .video {
    height: 18%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}

.MintContainter {
  justify-content: center;
  align-items: center;
  display: flex;
}

.MintBox {
  color: #fff;
  height: 200px;
  width: 300px;
  background-color: #141414;
  border-radius: 20px;
  position: relative;
}

body {
  background-color: #e40a20;
  overflow-x: hidden;
}

#root {
  background-color: #e81010;
}

.MuiSlider-thumb {
  background-color: #fff;
}

.MuiSlider-markLabel {
  color: #fff;
  font-size: 1.1em !important;
}

#mint-cost {
  color: #fff;
  padding-top: 5px;
  font-size: 1.3em;
}

.MuiSlider-track {
  color: #fff;
  height: 4px !important;
}

.MuiSlider-rail {
  color: #fff;
  height: 3px !important;
}

.MuiSlider-markLabel {
  color: #fffc !important;
}

.MuiSlider-markLabelActive {
  color: #fff !important;
}

.mint-container {
  background-color: #0d0084;
  border-radius: 18px;
  box-shadow: 3px 3px 20px 7px #fff;
}

.mintHeader {
  color: #fff;
  font-size: 1.1em;
}

#connect-btn {
  background-color: #e81010;
}

#faq-btn {
  width: 200px;
  height: 75px;
  background-color: #e81010;
}

#mint-button {
  background-color: #e81010;
}

.crossmint-button img {
  display: none;
}

.crossmint-button button {
  width: 200px;
  height: 75px;
  background-color: #e81010;
  border-radius: 8px;
}

.toggler h1 {
  color: #fff;
}

.toggle {
  width: 100px;
  height: 52px;
  background-color: #367ae8;
  border: 2px solid #fff;
  border-radius: 30px;
  display: inline-block;
  position: relative;
}

.toggle:after {
  content: "";
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
  transition: all .5s;
  position: absolute;
  top: 1px;
  left: 1px;
}

.toggler p {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.checkbox:checked + .toggle:after {
  left: 49px;
}

.checkbox:checked + .toggle {
  background-color: green;
}

.checkbox {
  display: none;
}

.mint-placeholder {
  min-height: 150px;
  width: 100%;
}

.crossmint-button {
  justify-content: center;
  display: flex;
}

#start-check {
  height: 75px;
  width: 200px;
  position: absolute;
}

.collectionSlider {
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 3px 3px 20px 7px #fff;
}

.collectionSlider .collectionSlider-row {
  width: 2130.5px;
  height: 197px;
  background-image: url("CollectionSlider.05505509.png");
  background-size: 2130.5px 197px;
  animation: 25s linear infinite slide;
}

@keyframes slide {
  from {
    background-position-x: 0;
  }

  to {
    background-position-x: -2130.5px;
  }
}

/*# sourceMappingURL=index.cbf13e15.css.map */
