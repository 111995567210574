.MintContainter {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Mint Box  */



.MintBox {
    background-color: #141414;
    color: white;
    height: 200px;
    width: 300px;
    position: relative;
    border-radius: 20px;


}



