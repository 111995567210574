#root {
    max-width: 1280px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
  }
  
  /* Main CSS */
  
  .App {
    /* height: 100vh; */
    text-align: center;
    font-family: proxima-nova, sans-serif;
  
  }
  
  .gap {
    height: 25px;
    width: 100%;
  }

  .minipadding{
    height: 1px;
    width: 100%;
    
  }
  
  
  .Desc {
    font-family: proxima-nova,sans-serif;
    font-weight: 100;
    font-style: normal;
    color: white;
    font-size: 1.05em;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding-left: 20%;
    padding-right: 20%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .SubTitle {
    font-family: proxima-nova,sans-serif;
    font-weight: 700;
    font-style: normal;
    color: #510c0c;
    font-size: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    
  }
  
  
  
  /* Titles  */
  
  
  .HeaderLogo {
    width: 55%;
    height: auto;  
  }
  
  .RoadMapLogo {
    width: 35%;
    /* height: auto;   */
  }
  
  .BioLogo {
    width: 35%;
    /* height: auto;   */
  }
  
  
  .AboutLogo {
    width: 35%;
    /* height: auto;   */
  }
  
  
  
  /* Sliders  */
  
  /* .slide-track {
    display: flex;
    position: relative;
    top: 575px;
    width: calc(550px * 18);
    animation: scroll 40s linear infinite;
    overflow: hidden;
  }
  
  @keyframes scroll {
    0% {
      transfrom: translateX(0);
    }
    100% {
      transform: translateX(calc(-200px * 6));
    }
  }
  
  
  
  
  .slide {
    height: 500px;
    width: 700px;
    display: flex;
    align-items: center;
    padding: 5px;
    overflow: hidden;
   
    
  } */
  
  
  
  
  
  
  
  
  
  
  /* YouTube Videos  */
  
  .video {
    height: 60%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px;
    padding-bottom: 25px;
  
  }
  

  
  @media screen and (max-width: 770px) {
  
    .Desc {
      font-family: proxima-nova,sans-serif;
      font-weight: 100;
      font-style: normal;
      /* font-size: 6px; */
      /* color: #510c0c; */
      
      
      justify-content: center;
      align-items: center;
      text-align: left;
     
      margin-left: auto;
      margin-right: auto;
      padding-left: 5%;
      padding-right: 5%;
    }
    
    .video {
      height: 18%;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    
    }
  }
  