$img-w: 4261px;
$img-h: 394px;
$shrink: 2;
$ratio: calc(1 / $shrink);
$speed: 25s;

.collectionSlider {
  position: relative;
  width: 100%;
  overflow: hidden;
  
  box-shadow: 3px 3px 20px 7px white;
  .collectionSlider-row {
    width: $img-w * $ratio;
    height: $img-h * $ratio;
    background-image: url(../../assets/images/CollectionSlider.png);
    // background-image: url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/111863/ubiquity-summit-web-01.png);
    background-size: $img-w * $ratio $img-h * $ratio;
    animation: slide $speed linear infinite;    
  }
}

@keyframes slide {
  from { background-position-x: 0; }
  to   { background-position-x: -$img-w * $ratio; }
}