body {
 overflow-x: hidden;
 background-color: rgb(228, 10, 32);
 /* rgb(228, 10, 32); */

}


#root {
    background-color: rgb(232, 16, 16);
    /* color: white; */
}

.MuiSlider-thumb{    
    /* color: white; */
    background-color: white;
    
    /* rgb(13, 0, 132); */
}

.MuiSlider-markLabel {
    font-size: 1.1em !important;    
    color: white;
}
#mint-cost {
    padding-top:5px;
    font-size: 1.3em;
    color: white;
}

.MuiSlider-track {
    color: white;
     /* rgb(13, 0, 132); */
    height: 4px !important;
}

.MuiSlider-rail{
    color: white;
    /* rgb(5, 0, 55); */
    height: 3px !important;
}

/* #slider-container{
    color: white
} */

.MuiSlider-markLabel {
    color: rgba(255, 255, 255, 0.8) !important;
}
.MuiSlider-markLabelActive {
    color: white !important;
}

.mint-container{
    background-color:rgb(13, 0, 132);
    box-shadow: 3px 3px 20px 7px white;
    border-radius: 18px;
    /* width: 100vh; */
 /* rgb(228, 10, 32);
    border: solid white 1px;
    */
}

.mintHeader{
    color: white;
    font-size: 1.1em;
}

#connect-btn{
    background-color: rgb(232, 16, 16);
    /* background-image: url("../../assets/images/ConnectedWallet.png"); */
}
#faq-btn{
    background-color: rgb(232, 16, 16);
    width: 200px;
    height: 75px;
    
    /* background-image: url("../../assets/images/ConnectedWallet.png"); */
}

#mint-button{
    /* background-image: url("../../assets/images/MintButton.png") */
    background-color: rgb(232, 16, 16);
    /* rgb(13, 0, 132); */
    /* rgba(64, 153, 255, 1) */
}

.crossmint-button img{
    display: none;
}

.crossmint-button button{
    width: 200px;
    height: 75px;
    background-color: rgb(232, 16, 16);
    border-radius: 8px;
}




.toggler h1 {
    color: white;
}
       
/* toggle in label designing */
.toggle {
    position : relative ;
    display : inline-block;
    width : 100px;
    height : 52px;
    background-color: rgb(54, 122, 232);
    border-radius: 30px;
    border: 2px solid white;
}
       
/* After slide changes */
.toggle:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: white;
    top: 1px;
    left: 1px;
    transition:  all 0.5s;
}
       
/* Toggle text */
.toggler p {
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}
       
/* Checkbox checked effect */
.checkbox:checked + .toggle::after {
    left : 49px;
}
       
/* Checkbox checked toggle label bg color */
.checkbox:checked + .toggle {
    background-color: green;
}
       
/* Checkbox vanished */
.checkbox {
    display : none;
}

.mint-placeholder {
    min-height:150px;
    width:100%;
}

.crossmint-button{
    display: flex;
    justify-content: center;
}

#start-check{
    height: 75px;
    width: 200px;
    position: absolute;
}